// 个人中心 // 播放历史
<template>
  <div id="app3">
    <!-- 顶部 -->
    <Top :white="$store.state.model.black" ref="top"></Top>

    <div class="history_body">
      <!-- 左侧 -->
      <Leftside></Leftside>
      <transition name="RightToleft">
        <!-- 右侧主题 -->
        <div class="his_r_box" v-show="enter">
          <div class="history_body_right">
            <div class="his_r_title"><span>安全设置</span></div>
            <!-- 主体 -->
            <div class="his_r_body">
              <div class="rech">
                <div class="rech_list safecent" @click="YS.gotoVip($event, 'open', 'secReg')">
                  <span>修改密码</span>
                  <img src="../assets/img/right.png" alt="前往修改密码内容" />
                </div>
                <!-- <div class="rech_list safecent" @click="YS.gotoVip($event, 'open', 'secmailReg')">
                  <span>通过邮箱修改密码</span>
                  <img src="../assets/img/right.png" alt="下一页" />
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </transition>
      <!-- 修改密码弹窗 -->
      <transition name="show">
        <div class="respowwin" v-show="$store.state.showlogin.secReg">
          <div class="login-logo"><img src="../assets/img/logo.png" alt="弹窗的logo" /></div>
          <div id="loginIpu" class="login-body">
            <!-- 单标签头 -->
            <div class="for-tag">
              <div id="ltc" class="lt-cc">
                <span id="ltcspan">修改密码</span>
              </div>
            </div>
            <!-- 账号密码输入框 -->
            <div class="lt-d">
              <input
                class="lt-input"
                v-model="respass.original_password"
                placeholder="请输入原密码"
              />
              <input
                class="lt-input"
                v-model="respass.new_password"
                placeholder="请输入新密码"
                type="password"
              />
              <input
                class="lt-input"
                v-model="conpass"
                placeholder="请确认新密码"
                type="password"
              />
              <div id="codeIpu" class="code-input">
                <input class="lt-input" maxlength="6" placeholder="请输入验证码" />
                <a id="codea" class="varcode" @click="YS.getCode">{{ $store.state.code.count }}</a>
              </div>
            </div>
          </div>
          <!-- 登录/注册按钮 -->
          <div class="lt-f">
            <div class="lt-acc">
              <div class="sec_re_div" :class="{ cliloading: loadCli }" @click="respow">
                <a class="acc_re">
                  <span v-show="!loadCli">立即修改</span>
                  <div class="dots-6" v-show="loadCli"></div>
                </a>
              </div>
            </div>
          </div>
          <img
            class="login-close"
            @click="YS.gotoVip($event, 'close', 'secReg')"
            src="../assets/img/close.png"
            alt="关闭修改密码弹窗"
          />
        </div>
      </transition>
      <!-- 邮箱修改密码弹窗 -->
      <transition name="show">
        <div class="respowwin_mail" v-show="$store.state.showlogin.secmailReg">
          <div class="login-logo"><img src="../assets/img/logo.png" alt="弹窗的logo" /></div>
          <div id="loginIpu" class="sec_code_body">
            <!-- 单标签头 -->
            <div class="for-tag">
              <div id="ltc" class="lt-cc">
                <span id="ltcspan">修改密码</span>
              </div>
            </div>
            <!-- 账号密码输入框 -->
            <div class="lt-d">
              <input class="lt-input" v-model="resmail.email" placeholder="请输入邮箱" />
              <input
                class="lt-input"
                v-model="resmail.new_password"
                placeholder="请输入新密码"
                type="password"
              />
              <div id="codeIpu" class="sec_code_inpu">
                <input
                  class="lt-input"
                  maxlength="6"
                  v-model="resmail.captcha"
                  placeholder="请输入邮箱验证码"
                />
                <a id="codea" class="varcode" @click="getmailver">{{ $store.state.code.count }}</a>
              </div>
            </div>
          </div>
          <!-- 登录/注册按钮 -->
          <div class="lt-f">
            <div class="lt-acc">
              <div class="sec_re_div" :class="{ cliloading: loadCli }" @click="cliresmail">
                <a class="acc_re">
                  <span v-show="!loadCli">立即修改</span>
                  <div class="dots-6" v-show="loadCli"></div>
                </a>
              </div>
            </div>
          </div>
          <img
            class="login-close"
            @click="YS.gotoVip($event, 'close', 'secmailReg')"
            src="../assets/img/close.png"
            alt="关闭邮箱修改密码弹窗"
          />
        </div>
      </transition>
      <!-- 遮罩层 -->
      <div id="cover"></div>
    </div>
  </div>
</template>
<script>
let that;
import Top from '../components/Top'; //导航top
import Leftside from '../components/Leftside'; //左侧主题
export default {
  components: {
    Top,
    Leftside,
  },
  name: 'History',
  data() {
    return {
      loadCli: false,
      enter: false,
      conpass: '', //确认密码
      //重置密码表单数据
      respass: {
        original_password: '',
        new_password: '',
      },
      // 通过邮箱充值密码表单数据
      resmail: {
        email: '',
        new_password: '',
        captcha: '',
        phrase_id: '',
      },
      secType: {
        def: { title: '修改密码', codeShow: false },
        mail: { title: '通过邮箱修改密码', codeShow: true },
      },
    };
  },
  created() {
    that = this;
    that.YS.toMobile('/user');
  },
  mounted() {
    that;
    that.enter = true;
    that.YS.closeallWin(); //关闭所有弹窗
    that.YS.actlist(that.$store.state.leftListcss, 6, 'history_lc_list_act'); //添加默认选中样式
    that.YS.iconAct(that.$store.state.leftComponent, 6); //添加默认选中样式
  },
  methods: {
    // 重置密码
    respow() {
      if (that.loadCli) return false;
      that.loadCli = true;
      if (
        !!that.conpass &&
        !!that.respass.new_password &&
        that.conpass === that.respass.new_password
      ) {
        that.api.user
          .api_changePassword(that.respass)
          .then((data) => {
            localStorage.setItem('token', JSON.stringify(data.token));
            that.$refs.top.usepubilDialog({ title: '修改密码成功', code: true });
            that.YS.gotoVip(1, 'close', 'secReg');
          })
          .catch((error) => {
            that.$refs.top.usepubilDialog({ title: error.message, code: false });
          })
          .finally(() => {
            that.loadCli = false;
          });
      } else if (that.conpass != that.respass.new_password) {
        that.loadCli = false;
        that.$refs.top.usepubilDialog({ title: '两次输入密码不一致', code: false });
      }
    },
    //通过邮箱重置密码
    cliresmail() {
      if (that.loadCli) return false;
      that.loadCli = true;
      that.api.user
        .api_resetPasswordByEmail(that.resmail)
        .then((data) => {
          localStorage.setItem('token', JSON.stringify(data.token));
          localStorage.setItem('user', JSON.stringify(data.user));
          that.$refs.top.usepubilDialog({ title: '修改密码成功', code: true });

          that.YS.gotoVip(1, 'close', 'secmailReg');
        })
        .finally(() => {
          that.loadCli = false;
        });
    },
    //获取邮箱验证码
    getmailver() {
      that.$store.state.code.count === '验证码' &&
        that.api.user.api_emailCode({ email: that.resmail.email }).then((data) => {
          that.YS.getCode();
          that.resmail.phrase_id = data.phrase_id;
        });
    },
  },
};
</script>
<style lang="scss" scope>
// .top-nav {
//   background: white;
// }
// .tag-input {
//   background: #eeeeee;
//   // border: 1px solid rgba(255, 255, 255, 0.43137254901960786);
// }

// .tag-span,
// .right-content span {
//   color: rgba(51, 51, 51, 1);
// }
// .bubble {
//   background: #91d6ff;
// }
</style>
